<template>
  <div class="container">

    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-button type="danger" plain @click="batchDelete">删除</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="questions"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <span>{{ props.row.answer }}</span>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="question"
          label="热门问题"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteOne(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EditContent
      :visible="visible"
      :type="type"
      :data="selectData"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="visible = false"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
// import { pageOptions } from '@/utils/config'
// import specData from '@/mock/specs.json'
import * as hotService from '@/api/hot'
import EditContent from './edit'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      search: '',
      questions: [],
      selected: [],
      visible: false,
      type: 'create',
      selectData: null
      // total: 100,
      // pageOptions: pageOptions,
      // currentPage: 1
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const res = await hotService.list({})
        console.log('res', res)
        this.questions = res
        // this.total = res.total
      } catch (err) {
        // handle error
      }
    },
    getItem (items) {
      console.log('items', items)
      return items.map(o => o.name).join(',')
    },
    handleTabChange (tab) {
      console.log('handleTabChange', tab)
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    handleCreate () {
      this.type = 'create'
      this.visible = true
    },
    edit (data) {
      this.selectData = R.clone(data)
      this.type = 'edit'
      this.visible = true
    },
    async handleConfirmCreate (data) {
      console.log('create data', data)
      try {
        await hotService.create(data)
        this.$notify.success({ title: '创建热门问题成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '创建热门问题失败' })
      } finally {
        this.visible = false
      }
    },
    async handleConfirmEdit (data) {
      try {
        await hotService.update(Object.assign(data, { id: this.selectData.id }))
        this.$notify.success({ title: '编辑1热门问题成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '编辑热门问题失败' })
      } finally {
        this.visible = false
      }
    },
    async deleteOne (id) {
      try {
        await hotService.deleteById(id)
        this.$notify.success({ title: '删除热门问题成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '删除热门问题失败' })
      }
    },
    async batchDelete () {
      if (!R.isEmpty(this.selected)) {
        const ids = this.selected.map(o => o.id)
        try {
          await hotService.batchDelete(ids)
          this.$notify.success({ title: '批量删除热门问题成功' })
          this.fetchData()
        } catch (err) {
          this.$notify.error({ title: '批量删除热门问题失败' })
        }
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

</style>
