<template>
  <el-dialog
    :title="type === 'create' ? '新建' : '编辑'"
    :visible.sync="visible"
    :before-close="cancel"
    :append-to-body="true"
    width="600px"
  >
    <field label="常见问题：" :asterisk="true" labelWidth="110px" :validation="validation.question">
      <el-input v-model="formData.question" placeholder="请输入常见问题" type="textarea"></el-input>
    </field>
    <field label="问题答案：" :asterisk="true" labelWidth="110px" :validation="validation.answer">
      <el-input v-model="formData.answer" placeholder="请输入问题答案" type="textarea" rows="10"></el-input>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
// import { baseURL } from '@/config.js'
import * as R from 'ramda'
const init = {
  question: '',
  answer: ''
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    type: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formData: R.clone(init)
    }
  },
  mixins: [validation({
    rules: {
      question: [v.required('请输入常见问题')],
      answer: [v.required('请输入问题答案')]
    },
    field: 'formData'
  })],
  methods: {
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    },
    async confirm () {
      await this.$validate()
      if (this.type === 'create') {
        this.$emit('onConfirmCreate', this.formData)
      } else {
        this.$emit('onConfirmEdit', this.formData)
      }
      this.formData = R.clone(init)
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (curVal, oldVal) {
        if (!R.isNil(curVal)) {
          this.formData = {
            question: curVal.question,
            answer: curVal.answer
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

</style>
